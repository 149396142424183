import React, { useState, useEffect } from "react"
import ModalVideo from "react-modal-video"
import styled from "styled-components"

import { Heading, Text } from "../../common/typography"
import { theme } from "../../../tokens"
import { useMobile } from "../../../utils/use-mobile"
import { VideoPlayButton } from "../../common/videoPlayButton/video-play-button"

const VideoItemGrid = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr;
  column-gap: 50px;
  padding: 50px 0px;
  align-items: center;
  @media (max-width: ${theme.breakpoints.md}) {
    grid-template-columns: 1fr;
  }
`

const VideoImage = styled.div.attrs(props => ({
  role: "img",
  title: "video thumbnail image",
}))`
  width: 400px;
  height: 300px;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.7);
  background-image: ${props => `url(${props.imageUrl})`};
  background-blend-mode: multiply;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: ${theme.breakpoints.lg}) {
    width: 100%;
    height: 220px;
  }
  @media (max-width: ${theme.breakpoints.md}) {
    height: 350px;
    margin-bottom: 42px;
  }
`

const VideoButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;

  @media (max-width: ${theme.breakpoints.md}) {
    width: 40%;
  }
  @media (max-width: ${theme.breakpoints.sm}) {
    width: 50%;
  }
`

export const VideoItem = ({ title, snippet, url }) => {
  const isMobile = useMobile()
  const youtubeThumbnail = `https://i.ytimg.com/vi_webp/${url}/0.webp`

  const [modalOpen, setModalOpen] = useState(false)
  const [isBrowser, setIsBrowser] = useState(false)

  useEffect(() => {
    if (!document.getElementById("wistia_script")) {
      var wistiaScript = document.createElement("script")
      wistiaScript.id = "wistia_script"
      wistiaScript.type = "text/javascript"
      wistiaScript.src = "https://fast.wistia.com/assets/external/E-v1.js"
      wistiaScript.async = true
      wistiaScript.popover = true
      document.body.appendChild(wistiaScript)
    }
  })

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setIsBrowser(true)
    }
  })

  const VideoPlayer = ({ url }) => (
    <ModalVideo
      channel="youtube"
      isOpen={modalOpen}
      videoId={url}
      onClose={() => setModalOpen(false)}
    />
  )

  if (isMobile) {
    return (
      <>
        <VideoItemGrid>
          <VideoImage imageUrl={youtubeThumbnail}>
            <VideoButtonContainer>
              <VideoPlayButton onClick={() => setModalOpen(true)} />
            </VideoButtonContainer>
          </VideoImage>
          <div>
            <Heading>{title}</Heading>
            <Text>{snippet}</Text>
          </div>
        </VideoItemGrid>
        {isBrowser && <VideoPlayer url={url} />}
      </>
    )
  } else {
    return (
      <>
        <VideoItemGrid>
          <div>
            <Heading>{title}</Heading>
            <Text>{snippet}</Text>
          </div>
          <VideoImage imageUrl={youtubeThumbnail}>
            <VideoButtonContainer>
              <VideoPlayButton onClick={() => setModalOpen(true)} />
            </VideoButtonContainer>
          </VideoImage>
        </VideoItemGrid>
        {isBrowser && <VideoPlayer url={url} />}
      </>
    )
  }
}
