import React, { useState } from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"

import * as styles from "./aff2022.module.scss"
import HeaderBanner from "../components/common/headerBanner/headerBanner"
import CTABanner from "../components/common/ctaBanner/ctaBanner"
import backgroundmin from "../../assets/header-bannerbg-ironcapx-min.png"
import background from "../../assets/header-bannerbg-ironcapx.png"
import { TM } from "../components/common/typography/trademark"
import { getStringWithTrademark } from "../utils/get-string-with-trademark"
import ProgressiveImage from "react-progressive-image-loading"
import ironcapLogo from "../../assets/IronCAP -protected by- logo white (480 x 110).png"
import pwcLogo from "../../assets/pwc-logo-white.png"
import { VideoItem } from "../components/sections/videos-list/video-item"

const trademarkedIronCAP = (
  <>
    IronCAP
    <TM />
  </>
)
const stringifiedTrademarkedIronCAP = getStringWithTrademark(trademarkedIronCAP)
const VIDEO_DETAILS = [
  {
    title: <>Proven protection against the quantum threat</>,
    snippet: (
      <>
        IronCAP
        <TM /> is a patent-protected cryptography system designed to operate on
        conventional computer systems, while simultaneously being secure enough
        to safeguard against future attacks from the world of quantum computers.
      </>
    ),
    url: "1Ql7fmGTB4c",
  },
  {
    title: (
      <>
        IronCAP X
        <TM /> - The premier end-to-end email encryption and signature system
      </>
    ),
    snippet: (
      <>
        IronCAP X
        <TM /> is the world's first end-to-end email/file encryption and digital
        signing system that utilizes patent-protected IronCAP
        <TM /> Toolkits as the underlying cryptographic system to ensure safety
        against cyber attacks today as well as future quantum attacks.
      </>
    ),
    url: "Kc6TGzEXs74",
  },
]

const AFFPage = ({ location }) => {
  return (
    <>
      <Layout hideHeader hideFooter>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`Asian Financial Forum 2022 | ${stringifiedTrademarkedIronCAP} and PwC`}</title>
          <link rel="canonical" href={location.pathname} />
        </Helmet>
        <ProgressiveImage
          preview={backgroundmin}
          src={background}
          transitionTime={500}
          transitionFunction="ease"
          render={(src, style) => (
            <div
              className={styles.headerBanner}
              style={Object.assign(style, { backgroundImage: `url(${src})` })}
            >
              <div className={styles.logosContainer}>
                <img src={pwcLogo} className={styles.logos}></img>
                <img src={ironcapLogo} className={styles.logos}></img>
              </div>
              <h1>Asian Financial Forum 2022</h1>
            </div>
          )}
        />
        <div className={styles.pageText}>
          <h2>
            Quantum-Safe Solution Jointly Presented By PwC China And IronCAP
            <TM />
          </h2>
          <p>
            PwC China and IronCAP
            <TM /> are working together to address the looming quantum threat by
            combining PwC China's experience and knowledge on digital risk and
            security and IronCAP
            <TM />
            's quantum-safe and mathematically proven Goppa code-based and
            Hash-based cryptography. IronCAP
            <TM /> provides the capabilities to protect against today and
            tomorrow's malicious attacks.
          </p>
        </div>
        <div style={{ marginBottom: "86px" }}>
          {VIDEO_DETAILS.map((video, i) => (
            <VideoItem
              title={video.title}
              snippet={video.snippet}
              url={video.url}
              key={video.url + i}
            />
          ))}
        </div>
        <CTABanner
          tagline="Step Into The Future"
          CTA="video"
          CTADetail="how video"
        />
      </Layout>
    </>
  )
}

export default AFFPage
