import styled from "styled-components"
import buttonImage from "../../../../assets/play.png"

export const VideoPlayButton = styled.button`
  background-image: url(${buttonImage});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  width: 100%;
  height: 100%;
  padding-top: 100%;
  background-color: transparent;
  border: none;
`
